import { Box } from '@mui/material'
import React from 'react'
import Navbar from '../Navbar/Navbar'

function Allproducts() {
  return (
    <Box>
    <Navbar />
    
  </Box>
  )
}

export default Allproducts
